export const SEATS = {
  Y: '일반석',
  C: '프레스티지석',
};

export const TRIP_TYPES = {
  RT: '왕복',
  OW: '편도',
};

export const LOCATION_CODES = {
  AKL: '오클랜드',
  AMS: '암스테르담/스키폴',
  AOJ: '아오모리',
  ATL: '애틀랜타/하츠필드잭슨, GA',
  BCN: '바르셀로나',
  BKK: '방콕',
  BNE: '브리즈번',
  BOM: '뭄바이',
  BOS: '보스턴,MA',
  BUD: '부다페스트',
  CAN: '광저우',
  CDG: '파리/샤를 드 골',
  CEB: '세부',
  CGK: '자카르타/수카르노 하타',
  CGO: '정저우',
  CHI: '시카고/모든 공항, IL',
  CJJ: '청주',
  CJU: '제주',
  CMB: '콜롬보',
  CNX: '치앙마이',
  CRK: '클락/앙헬레스',
  CSX: '창사',
  CTS: '삿포로/치토세',
  CXR: '나트랑',
  DAD: '다낭',
  DEL: '델리',
  DFW: '댈러스/모든 공항, TX',
  DLC: '다롄',
  DLI: '달랏',
  DPS: '덴파사르 (발리)',
  DXB: '두바이',
  DYG: '장자제',
  FCO: '로마/레오나르도 다빈치',
  FRA: '프랑크푸르트',
  FUK: '후쿠오카',
  GMP: '서울/김포',
  GUM: '괌',
  HAN: '하노이',
  HFE: '허페이',
  HGH: '항저우',
  HIN: '진주/사천',
  HKG: '홍콩',
  HKT: '푸껫',
  HND: '도쿄/하네다',
  HNL: '호놀룰루 (하와이), HI',
  IAD: '워싱턴/덜레스, DC',
  ICN: '서울/인천',
  IKT: '이르쿠츠크',
  IST: '이스탄불',
  JFK: '뉴욕/존 F. 케네디, NY',
  KIJ: '니가타',
  KIX: '오사카/간사이',
  KMG: '쿤밍',
  KMQ: '고마쓰',
  KOJ: '가고시마',
  KPO: '포항/경주',
  KTM: '카트만두',
  KUL: '쿠알라룸푸르',
  KWJ: '광주',
  LAS: '라스베이거스/해리 리드, NV',
  LAX: '로스앤젤레스, CA',
  LED: '상트페테르부르크/풀코보',
  LHR: '런던/히스로',
  MAD: '마드리드',
  MDG: '무단장',
  MLE: '말레',
  MNL: '마닐라',
  MXP: '밀라노/말펜사',
  NGO: '나고야',
  NKG: '난징',
  NRT: '도쿄/나리타',
  NYC: '뉴욕/모든 공항, NY',
  OIT: '오이타',
  OKA: '오키나와',
  OKJ: '오카야마',
  ORD: '시카고/오헤어, IL',
  PEK: '베이징/서우두',
  PNH: '프놈펜',
  PRG: '프라하',
  PUS: '부산/김해',
  PVG: '상하이/푸동',
  RGN: '양곤',
  ROR: '팔라우',
  RSU: '여수/순천',
  SEA: '시애틀/타코마, WA',
  SEL: '서울/모든 공항',
  SFO: '샌프란시스코, CA',
  SGN: '호찌민',
  SHA: '상하이/모든 공항',
  SHE: '선양',
  SIN: '싱가포르/창이',
  SVO: '모스크바/셰레메체보',
  SYD: '시드니/킹즈퍼드 스미스',
  SZX: '선전',
  TAE: '대구',
  TAO: '칭다오',
  TAS: '타슈켄트',
  TLV: '텔아비브/벤 구리온',
  TNA: '지난',
  TPE: '타이베이/타오위엔',
  TSN: '톈진',
  TXN: '황산',
  TYO: '도쿄/모든 공항',
  UBN: '울란바타르 신공항',
  URC: '우루무치',
  USN: '울산',
  VIE: '비엔나',
  VVO: '블라디보스토크',
  WAS: '워싱턴/모든 공항, DC',
  WEH: '웨이하이',
  WUH: '우한',
  XIY: '시안/셴양',
  XMN: '샤먼',
  YNJ: '옌지',
  YVR: '밴쿠버, BC',
  YYZ: '토론토/피어슨, ON',
  ZAG: '자그레브',
  ZRH: '취리히',
} as const;

export type Seat = keyof typeof SEATS;
export type TripType = keyof typeof TRIP_TYPES;
export type LocationCode = keyof typeof LOCATION_CODES;
